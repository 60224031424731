import React from "react";
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {StatsContainer} from './StatsContainer'
import {RankContainer} from "./RankContainer";

export function RankingRow({data}) {

    return <Box m={1}>
            <Card className="stats-item" variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="h2" color="textPrimary" gutterBottom>
                        {data.title}
                    </Typography>
                    {data.summary && <Typography variant="h5" component="p" color="textSecondary">
                        {data.summary}
                    </Typography>}
                    {data.stats && <StatsContainer legend={data.legend} data={data.stats}/>}
                    {data.rank && <RankContainer legend={data.legend} data={data.rank}/>}
                    {data.source && <Typography variant="caption" display="block" color="textSecondary">
                        <br/>
                        Źródło: <a href={data.source} target="_blank" rel="noreferrer">{data.source}</a>
                    </Typography>}
                </CardContent>
                {data.readMore && <CardActions>
                    <Button size="small" color="primary" target="_blank" href={data.readMore}>Więcej</Button>
                </CardActions>}
            </Card>
        </Box>
}