import React from "react";
import { theme } from "../App"

import {
    ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export function StatsContainer({legend, data}) {
    return <div style={{width: '100%', height: 300, paddingTop: '20px'}}>
        <ResponsiveContainer>
            <BarChart
                data={data}
                margin={{
                    top: 5, right: 5, left: 5, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="country"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar name={legend} dataKey="value" fill={theme.palette.primary.main}>
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.country === 'Pisland' ? theme.palette.primary.main : theme.palette.secondary.light}/>
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    </div>
}
