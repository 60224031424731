export const rankings = [
    {
        title: "28 miejsce pod względem siły nabywczej pieniądza w Europie",
        readMore: "https://www.pb.pl/polska-na-28-miejscu-w-rankingu-sily-nabywczej-w-europie-1006301",
        source: "https://www.gfk.com/insights/map-of-the-month-gfk-purchasing-power-europe-2020"
    },
    {
        title: "Najwyższe ceny hurtowe prądu w UE",
        readMore: "https://energia.rp.pl/energetyka-zawodowa/ceny-energii/24905-polska-ma-najdrozszy-prad-w-ue"
    },
    {
        title: "Jedne z najdroższych paliw pod względem siły nabywczej",
        readMore: "https://biznes.wprost.pl/gospodarka/10348432/ile-paliwa-kupi-sie-za-srednia-pensje-w-polsce-i-na-swiecie.html",
        source: "https://www.picodi.com/pl/mozna-taniej/indeks-benzyny-2020",
        legend: "Litry paliwa",
        stats: [
            {country: 'Albania', value: 239},
            {country: 'Ukraina', value: 345},
            {country: 'Mołdawia', value: 352},
            {country: 'Serbia', value: 429},
            {country: 'Macedonia Północna', value: 443},
            {country: 'Czarnogóra', value: 455},
            {country: 'Turcja', value: 484},
            {country: 'Bośnia i Hercegowina', value: 512},
            {country: 'Bułgaria', value: 532},
            {country: 'Białoruś', value: 553},
            {country: 'Grecja', value: 632},
            {country: 'Portugalia', value: 669},
            {country: 'Rumunia', value: 673},
            {country: 'Słowacja', value: 694},
            {country: 'Łotwa', value: 718},
            {country: 'Węgry', value: 745},
            {country: 'Chorwacja', value: 772},
            {country: 'Pisland', value: 832},
            {country: 'Litwa', value: 836},
            {country: 'Czechy', value: 881},
            {country: 'Estonia', value: 915},
            {country: 'Rosja', value: 919},
            {country: 'Włochy', value: 929},
            {country: 'Malta', value: 939},
            {country: 'Słowenia', value: 1127},
            {country: 'Hiszpania', value: 1127},
            {country: 'Francja', value: 1462},
            {country: 'Holandia', value: 1502},
            {country: 'Wielka Brytania', value: 1537},
            {country: 'Cypr', value: 1626},
            {country: 'Finlandia', value: 1693},
            {country: 'Austria', value: 1746},
            {country: 'Belgia', value: 1766},
            {country: 'Islandia', value: 1832},
            {country: 'Andora', value: 1834},
            {country: 'Niemcy', value: 1938},
            {country: 'Szwecja', value: 1949},
            {country: 'Irlandia', value: 2024},
            {country: 'Norwegia', value: 2229},
            {country: 'Dania', value: 2484},
            {country: 'Luksemburg', value: 3320},
            {country: 'Szwajcaria', value: 3795},
        ]
    },
    {
        title: "Ogon Europy pod względem dobrobytu finansowego",
        readMore: "https://forsal.pl/artykuly/1442064,oto-najlepsze-i-najgorsze-kraje-europy-pod-wzgledem-dobrobytu-finansowego-ranking.html",
        source: "https://www.intrum.com/publications/european-consumer-payment-report/european-consumer-payment-report-2019/",
        legend: "Punktacja (0-10)",
        stats: [
            {country: 'Grecja', value: 5.3},
            {country: 'Litwa', value: 5.54},
            {country: 'Pisland', value: 5.54},
            {country: 'Łotwa', value: 5.55},
            {country: 'Estonia', value: 5.85},
            {country: 'Rumunia', value: 5.86},
            {country: 'Portugalia', value: 6.06},
            {country: 'Czechy', value: 6.16},
            {country: 'Słowacja', value: 6.19},
            {country: 'Węgry', value: 6.24},
            {country: 'Hiszpania', value: 6.27},
            {country: 'Francja', value: 6.3},
            {country: 'Holandia', value: 6.31},
            {country: 'Belgia', value: 6.31},
            {country: 'Irlandia', value: 6.34},
            {country: 'Włochy', value: 6.35},
            {country: 'Wielka Brytania', value: 6.38},
            {country: 'Dania', value: 6.4},
            {country: 'Norwegia', value: 6.47},
            {country: 'Finlandia', value: 6.55},
            {country: 'Szwajcaria', value: 6.65},
            {country: 'Szwecja', value: 6.72},
            {country: 'Austria', value: 6.77},
            {country: 'Niemcy', value: 6.89},
        ]
    },
    {
        title: "27 miejsce wśród państw OECD pod względem elastyczność i zatrudnienia",
        summary: "Spadek o 10 miejsc od 2015",
        readMore: "https://www.prawo.pl/kadry/30-miejsce-polski-w-rankingu-elastycznosci-zatrudnienia-for,371239.html",
        source: "https://for.org.pl/en/a/7464,for-communication-1/2020-employment-flexibility-index-2020-one-size-does-not-fit-all"
    },
    {
        title: "Drugi po Grecji najbardziej zapracowany naród w UE",
        readMore: "https://www.money.pl/gospodarka/dzien-pracoholika-polacy-jednym-z-najbardziej-zapracowanych-narodow-6411720137332353a.html",
        source: "https://data.oecd.org/emp/hours-worked.htm",
        legend: "Przepracowane godziny rocznie",
        stats: [
            {country: "Meksyk", value: 2137.0},
            {country: "Kostaryka", value: 2059.6},
            {country: "Korea", value: 1967.0},
            {country: "Rosja", value: 1965.0},
            {country: "Grecja", value: 1949.0},
            {country: "Chile", value: 1914.4},
            {country: "Izrael", value: 1898.1},
            {country: "Pisland", value: 1806.0},
            {country: "Czechy", value: 1788.0},
            {country: "Nowa Zelandia", value: 1779.0},
            {country: "Stany Zjednoczone", value: 1779.0},
            {country: "Irlandia", value: 1772.0},
            {country: "Węgry", value: 1725.2},
            {country: "Portugalia", value: 1719.0},
            {country: "Włochy", value: 1717.8},
            {country: "Australia", value: 1712.0},
            {country: "Estonia", value: 1711.0},
            {country: "Słowacja", value: 1695.0},
            {country: "Hiszpania", value: 1686.0},
            {country: "Kanada", value: 1670.0},
            {country: "Łotwa", value: 1661.0},
            {country: "Japonia", value: 1644.0},
            {country: "Litwa", value: 1635.0},
            {country: "Słowenia", value: 1592.9},
            {country: "Belgia", value: 1583.0},
            {country: "Szwajcaria", value: 1556.9},
            {country: "Finlandia", value: 1540.0},
            {country: "Wielka Brytania", value: 1538.0},
            {country: "Luksemburg", value: 1506.0},
            {country: "Francja", value: 1505.0},
            {country: "Austria", value: 1501.0},
            {country: "Islandia", value: 1454.0},
            {country: "Szwecja", value: 1452.0},
            {country: "Holandia", value: 1434.0},
            {country: "Niemcy", value: 1386.1},
            {country: "Norwegia", value: 1384.0},
            {country: "Dania", value: 1380.0},
        ]
    },
    {
        title: "Ogon Europy pod względem produktywności pracy",
        readMore: "https://300gospodarka.pl/news/polska-produktywnosc-rosnie-bardzo-szybko-ale-to-wcale-nie-znaczy-ze-doganiamy-zachod",
    },
    {
        title: "Kwota wolna najniższa w UE",
        readMore: "https://portfelpolaka.pl/kwota-wolna-od-podatku/",
        legend: "Wartość kwoty wolnej w PLN (przeliczone z euro po kursie 4.5PLN)",
        stats: [
            {country:"Rumunia* ", value: 0},
            {country:"Węgry ", value: 0},
            {country:"Bułgaria ", value: 0},
            {country:"Pisland", value: 3091},
            {country:"Łotwa ", value: 3420},
            {country:"Zambia ", value: 3571},
            {country:"Tanzania ", value: 3966},
            {country:"Estonia ", value: 4626},
            {country:"Indonezja ", value: 6576},
            {country:"Irlandia* ", value: 7425},
            {country:"Szwecja ", value: 9103.5},
            {country:"Holandia ", value: 9463.5},
            {country:"Indie ", value: 10015},
            {country:"Maroko ", value: 11127},
            {country:"Bangladesz ", value: 12000},
            {country:"Namibia ", value: 12166},
            {country:"Botswana ", value: 12938},
            {country:"Tajlandia ", value: 14040},
            {country:"Meksyk ", value: 15312},
            {country:"Chorwacja ", value: 15403.5},
            {country:"Słowenia* ", value: 15750},
            {country:"Portugalia ", value: 18468},
            {country:"Słowacja* ", value: 19863},
            {country:"RPA ", value: 20362},
            {country:"Włochy ", value: 21600},
            {country:"Grecja ", value: 22500},
            {country:"Francja ", value: 26833.5},
            {country:"Dania ", value: 27900},
            {country:"Brazylia ", value: 28085},
            {country:"Ekwador ", value: 31752},
            {country:"Dominikana ", value: 35350},
            {country:"Niemcy ", value: 36585},
            {country:"Trynidad i Tobago ", value: 37336},
            {country:"Belgia ", value: 39060},
            {country:"Panama ", value: 43482},
            {country:"Kolumbia ", value: 47512},
            {country:"Barbados ", value: 49411},
            {country:"Austria ", value: 49950},
            {country:"Luksemburg ", value: 52312.5},
            {country:"Malta ", value: 53550},
            {country:"Kostaryka ", value: 70516},
        ]
    },
    {
        title: "Jedna z najwyższych inflacji w UE",
        readMore: "https://www.bankier.pl/wiadomosc/Inflacja-w-Polsce-wciaz-najwyzsza-w-UE-7927234.html",
    },
    {
        title: "Poziom wydatków socjalnych najwyższy w stosunku do PKB na świecie",
        source: "https://inequalityindex.org/#/",
        readMore: "https://bezprawnik.pl/polski-socjal/",
        legend: "miejsce w rankingu - tylko kraje z OECD",
        rank: [
            {country:"Pisland", value: 1},
            {country:"Finlandia", value: 2},
            {country:"Francja", value: 3},
            {country:"Irlandia", value: 4},
            {country:"Niemcy", value: 5},
            {country:"Słowenia", value: 6},
            {country:"Belgia", value: 7},
            {country:"Dania", value: 8},
            {country:"Japonia", value: 9},
            {country:"Austria", value: 10},
            {country:"Swzwecja", value: 11},
            {country:"Czechy", value: 12},
            {country:"Wielka Brytania", value: 13},
            {country:"Norwegia", value: 14},
            {country:"Holandia", value: 15},
            {country:"Stany Zjednoczone", value: 16},
            {country:"Włochy", value: 18},
            {country:"Słowacja", value: 20},
            {country:"Nowa Zelandia", value: 21},
            {country:"Hiszpania", value: 22},
            {country:"Luksemburg", value: 23},
            {country:"Islandia", value: 25},
            {country:"Kanada", value: 26},
            {country:"Estonia", value: 27},
            {country:"Portugalia", value: 28},
            {country:"Australia", value: 30},
            {country:"Węgry", value: 32},
            {country:"Izrael", value: 33},
            {country:"Grecja", value: 35},
            {country:"Szwajcaria", value: 36},
            {country:"Chile", value: 37},
            {country:"Łotwa", value: 38},
            {country:"Korea", value: 54},
            {country:"Meksyk", value: 68},
            {country:"Turcja", value: 82},
        ]
    },
    {
        title: "Ostatnie miejsce pod względem opodatkowania konsumpcji w UE",
        readMore: "https://www.podatki.biz/sn_autoryzacja/logowanie.php5/artykuly/opodatkowanie-konsumpcji-polska-na-ostatnim-miejscu-w-europie_16_45878.htm?idDzialu=16&idArtykulu=45878",
    },
    {
        title: "77 miejsce w rankingu wolności gospodarczej (Fraser institute)",
        summary: "\"Spadek z 59. na 77. miejsce. Polska w rankingu wolności gospodarczej między Serbią i Kirgistanem\"",
        source: "https://www.fraserinstitute.org/economic-freedom/map?geozone=world&page=map&year=2018",
        readMore: "https://www.biznesinfo.pl/ranking-wolnosci-gospodarczej-140920-pt-polska",
    },
    {
        title: "40 miejsce w rankingu Doing Buisness",
        summary: "\"To kolejny rok spadków – rok temu byliśmy na 33. miejscu, a dwa lata wcześniej na 27.\"",
        source: "https://www.doingbusiness.org/en/reports/global-reports/doing-business-2020",
        readMore: "https://www.money.pl/gospodarka/doing-business-2020-polska-znow-spadla-w-waznym-rankingu-6438524970350209a.html"
    },
    {
        title: "Ogon Europy w rankingu państw przyjaznych nowym przedsiębiorcom",
        source: "https://aleo.com/pl/blog/europejskie-perspektywy-przedsiebiorczosci",
        readMore: "https://www.forumfirm.eu/ranking-panstw-przyjaznych-nowym-przedsiebiorcom-polska-w-ogonie-europy/",
    },
    {
        title: "38 miejsce w rankingu konkurencyjności gospodarek w 2019",
        summary: "Spadek na 39 w 2020",
        source: "https://www.imd.org/wcc/world-competitiveness-center-rankings/world-competitiveness-ranking-2020/",
        readMore: "https://300gospodarka.pl/news/swiatowy-ranking-konkurencyjnosci-2019-polska-spada-o-4-miejsca-litwa-bryluje-w-regionie",
        legend: "miejsce w rankingu",
        rank: [
            {country:"Singapur", value: 1},
            {country:"Dania", value: 2},
            {country:"Szwajcaria", value: 3},
            {country:"Holandia", value: 4},
            {country:"Hong Kong", value: 5},
            {country:"Szwecja", value: 6},
            {country:"Norwegia", value: 7},
            {country:"Kanada", value: 8},
            {country:"Zjednoczone Emiraty Arabskie", value: 9},
            {country:"Stany Zjednoczone", value: 10},
            {country:"Tajwan", value: 11},
            {country:"Irlandia", value: 12},
            {country:"Finlandia", value: 13},
            {country:"Katar", value: 14},
            {country:"Luksemburg", value: 15},
            {country:"Austria", value: 16},
            {country:"Niemcy", value: 17},
            {country:"Australia", value: 18},
            {country:"Wielka Brytania", value: 19},
            {country:"Chiny", value: 20},
            {country:"Islandia", value: 21},
            {country:"Nowa Zelandia", value: 22},
            {country:"Korea", value: 23},
            {country:"Arabia Saudyjska", value: 24},
            {country:"Belgia", value: 25},
            {country:"Izrael", value: 26},
            {country:"Malezja", value: 27},
            {country:"Estonia", value: 28},
            {country:"Tajlandia", value: 29},
            {country:"Cypr", value: 30},
            {country:"Litwa", value: 31},
            {country:"Francja", value: 32},
            {country:"Czechy", value: 33},
            {country:"Japonia", value: 34},
            {country:"Słowenia", value: 35},
            {country:"Hipszania", value: 36},
            {country:"Portugalia", value: 37},
            {country:"Chile", value: 38},
            {country:"Pisland", value: 39},
            {country:"Indonezja", value: 40},
            {country:"Łotwa", value: 41},
            {country:"Kazachstan", value: 42},
            {country:"Indie", value: 43},
            {country:"Włochy", value: 44},
            {country:"Filipiny", value: 45},
            {country:"Turcja", value: 46},
            {country:"Węgry", value: 47},
            {country:"Bułgaria", value: 48},
            {country:"Grecja", value: 49},
            {country:"Rosja", value: 50},
            {country:"Rumunia", value: 51},
            {country:"Peru", value: 52},
            {country:"Meksyk", value: 53},
            {country:"Kolumbia", value: 54},
            {country:"Ukraina", value: 55},
            {country:"Brazylia", value: 56},
            {country:"Słowacja", value: 57},
            {country:"Jordania", value: 58},
            {country:"RPA", value: 59},
            {country:"Chorwacja", value: 60},
            {country:"Mongolia", value: 61},
            {country:"Argentyna", value: 62},
            {country:"Wenezuela", value: 63},
        ]
    },
    {
        title: "Ogon Europy pod względem innowacji",
        readMore: "https://oko.press/innowacje-w-polsce/",
        source: "https://ec.europa.eu/growth/industry/policy/innovation/scoreboards_en",
        legend: "Indeks innowacji",
        stats: [
            {country:"Rumunia", value: 0.160281564011867},
            {country:"Ukraina", value: 0.167015141984015},
            {country:"Czarnogóra", value: 0.220020984614088},
            {country:"Północna Macedonia", value: 0.22590407734857},
            {country:"Bułgaria", value: 0.23045054068519},
            {country:"Chorwacja", value: 0.2980916215588},
            {country:"Pisland", value: 0.298501370962079},
            {country:"Serbia", value: 0.312831007216839},
            {country:"Turcja", value: 0.315830014956186},
            {country:"Łotwa", value: 0.319678918451118},
            {country:"Węgry", value: 0.336805176376317},
            {country:"Słowacja", value: 0.337696483609814},
            {country:"Grecja", value: 0.388924768286088},
            {country:"Litwa", value: 0.404290967968791},
            {country:"Włochy", value: 0.419971334449322},
            {country:"Malta", value: 0.426091815043117},
            {country:"Czechy", value: 0.427365257253393},
            {country:"Słowenia", value: 0.430615615970302},
            {country:"Hiszpania", value: 0.431577447737058},
            {country:"Cypr", value: 0.450816022446943},
            {country:"Portugalia", value: 0.490492654328985},
            {country:"Estonia", value: 0.501988939063539},
            {country:"Francja", value: 0.529942880777852},
            {country:"Izrael", value: 0.563279914646695},
            {country:"Irlandia", value: 0.568082901080929},
            {country:"Islandia", value: 0.578781971657049},
            {country:"Austria", value: 0.595743241212131},
            {country:"Niemcy", value: 0.607932356525122},
            {country:"Norwegia", value: 0.610799608807035},
            {country:"Wielka Brytania", value: 0.613123896229898},
            {country:"Belgia", value: 0.614917048616663},
            {country:"Luksemburg", value: 0.638943850985982},
            {country:"Holandia", value: 0.648005601463779},
            {country:"Dania", value: 0.682007205927303},
            {country:"Finlandia", value: 0.709272668407464},
            {country:"Szwecja", value: 0.713441978357159},
            {country:"Szwajcaria", value: 0.837432766219109},
        ]
    },
    {
        title: "System podatkowy 34 wśród 36 OECD",
        readMore: "https://forsal.pl/gospodarka/artykuly/7848334,polski-system-podatkowy-na-34-miejscu-wsrod-36-panstw-oecd-za-nami-tylko-chile-i-wlochy.html",
        source: "https://taxfoundation.org/2020-international-tax-competitiveness-index/",
        legend: "Ogólny wynik w rankingu",
        stats: [
            {country:"Włochy", value: 44.3},
            {country:"Chile", value: 46.3},
            {country:"Pisland", value: 46.6},
            {country:"Portugalia", value: 46.9},
            {country:"Francja", value: 50.7},
            {country:"Meksyk", value: 51.5},
            {country:"Islandia", value: 55.4},
            {country:"Grecja", value: 55.9},
            {country:"Dania", value: 58.3},
            {country:"Hiszpania", value: 58.5},
            {country:"Japonia", value: 59.1},
            {country:"Izrael", value: 59.2},
            {country:"Korea", value: 59.9},
            {country:"Słowenia", value: 61.4},
            {country:"Wielka Brytania", value: 61.6},
            {country:"Stany Zjednoczone", value: 62.9},
            {country:"Irlandia", value: 63.1},
            {country:"Belgia", value: 64.1},
            {country:"Kanada", value: 65.3},
            {country:"Holandia", value: 65.5},
            {country:"Finlandia", value: 65.7},
            {country:"Węgry", value: 67.9},
            {country:"Niemcy", value: 67.9},
            {country:"Norwegia", value: 68.1},
            {country:"Austria", value: 68.7},
            {country:"Słowacja", value: 69.9},
            {country:"Turcja", value: 69.9},
            {country:"Australia", value: 71.4},
            {country:"Czechy", value: 73.1},
            {country:"Szwecja", value: 74},
            {country:"Litwa", value: 75.8},
            {country:"Luksemburg", value: 76},
            {country:"Szwajcaria", value: 77.1},
            {country:"Nowa Zelandia", value: 82.4},
            {country:"Łotwa", value: 84.4},
            {country:"Estonia", value: 100},
        ]
    },
    {
        title: "Przedostatnie miejsce w Europie względem konkurencyjności podatkowej",
        summary: "Identyczny ranking do tego powyżej, tylko, że z 2019 ",
        readMore: "https://gospodarka.dziennik.pl/podatki/artykuly/609561,podatki-system-podatkowy-francja-unia-europejska.html",
        source: "https://taxfoundation.org/2019-international-index/",
    },
    {
        title: "Jedne z najdroższych autostrad",
        readMore: "https://www.rp.pl/Transport-drogowy/309309863-Platne-autostrady-w-Polsce-wsrod-najdrozszych-w-UE.html"
    },
    {
        title: "Najwięcej ofiar wypadków drogowych w UE",
        readMore: "https://krakow.wyborcza.pl/krakow/7,44425,26035855,77-ofiar-na-milion-mieszkancow-polska-nadal-czarnym-punktem.html",
        source: "https://ec.europa.eu/commission/presscorner/detail/en/qanda_20_1004",
        legend: "Liczba ofiar wypadków na milion mieszkańców",
        stats: [
            {country:"Rumunia", value: 96},
            {country:"Bułgaria", value: 89},
            {country:"Pisland", value: 77},
            {country:"Chorwacja", value: 73},
            {country:"Łotwa", value: 69},
            {country:"Litwa", value: 66},
            {country:"Grecja", value: 65},
            {country:"Węgry", value: 62},
            {country:"Portugalia", value: 61},
            {country:"Cypr", value: 59},
            {country:"Czechy", value: 58},
            {country:"Belgia", value: 56},
            {country:"Włochy", value: 55},
            {country:"Słowacja", value: 51},
            {country:"Słowenia", value: 49},
            {country:"Francja", value: 48},
            {country:"Austria", value: 46},
            {country:"Estonia", value: 39},
            {country:"Finlandia", value: 37},
            {country:"Niemcy", value: 37},
            {country:"Luksemburg", value: 36},
            {country:"Hiszpania", value: 36},
            {country:"Dania", value: 35},
            {country:"Holandia", value: 34},
            {country:"Malta", value: 32},
            {country:"Irlandia", value: 29},
            {country:"Szwecja", value: 22},
        ]
    },
    {
        title: "57 miejsce na świecie pod względem jakości dróg",
        readMore: "https://forsal.pl/transport/drogi/artykuly/7676029,ranking-jakosci-drog-na-swiecie-polska-w-ogonie-europy.html,komentarze-popularne,1",
        source: "https://www.theglobaleconomy.com/rankings/roads_quality/"
    },
    {
        title: "Ostatnie miejsce w UE w elektromobilności",
        readMore: "https://moto.rp.pl/tu-i-teraz/34840-polska-w-elektromobilnosci-na-koncu-ue-2",
    },
    {
        title: "Ogon w rankingu kolei w Europie",
        readMore: "https://www.rp.pl/artykul/1003530-Raport-BCG---stan-kolei-w-Europie.html",
    },
    {
        title: "Jedna z najbardziej niedofinansowanych służb zdrowia w UE",
        readMore: "https://www.medonet.pl/zdrowie,polska-na-szarym-koncu-w-europie-w-rankingu-dotyczacym-ochrony-zdrowia,artykul,1732016.html"
    },
    {
        title: "Ogon Europy w rankingu systemów ochrony zdrowia EHCI",
        readMore: "https://izba-lekarska.pl/prasa/polska-na-koncu-rankingu-systemow-ochrony-zdrowia-za-nami-tylko-wegry-rumunia-i-albania/",
    },
    {
        title: "Najmniejsza liczba lekarzy i pielęgniarek na mieszkańca w UE",
        readMore: "https://www.euractiv.pl/section/bezpieczenstwo-i-obrona/news/liczba-lekarzy-i-pielegniarek-na-tysiac-mieszkancow-w-panstwach-europy-polska-na-szarym-koncu-w-rankingu-pielegniarek-per-capita/",
    },
    {
        title: "W całej UE spadają zgony na raka, w Polsce rosną",
        readMore: "https://www.dw.com/pl/w-ue-spada-liczba-zgon%C3%B3w-na-raka-z-wyj%C4%85tkiem-polski/a-53188530",
    },
    {
        title: "Ostatnie miejsce pod względem dostępu do antykoncepcji w Europie",
        readMore: "https://zdrowie.wprost.pl/dziecko/ciaza/10379933/aborcja-eugeniczna-zakazana-polska-z-najgorszym-dostepem-do-antykoncepcji-w-europie.html",
    },
    {
        title: "Najstarsza kadra lekarska w UE",
        readMore: "https://pulsmedycyny.pl/co-czwarty-lekarz-w-polsce-ma-wiecej-niz-65-lat-1005481",
    },
    {
        title: "Jedna z najgorszych jakości powietrza w Europie",
        readMore: "https://www.euractiv.pl/section/energia-i-srodowisko/news/polska-w-ogonie-europy-pod-wzgledem-jakosci-powietrza/\n",
    },
    {
        title: "Jedyny kraj w UE sprzeciwiający się wdrożeniu programu redukcji węgla",
        readMore: "https://www.green-news.pl/642-neutralnosc-klimatyczna-rada-europejska-2050",
    },
    {
        title: "Ostatnie miejsce w UE w realizacji celów klimatycznych",
        readMore: "http://www.chronmyklimat.pl/projekty/dofinansowania-i-dotacje/debata/7/polska-na-ostatnim-miejscu-w-ue-w-realizacji-celow-klimatycznych"
    },
    {
        title: "Jedne z najdroższych śmieci",
        readMore: "https://businessinsider.com.pl/firmy/ceny-wywozu-smieci-drozeja-dwukrotnie-berlin-tanszy-od-warszawy-i-poznania/99vcfhg",
    },
    {
        title: "Płonące wysypisko Europy i świata",
    },
    {
        title: "Najmniej wód gruntowych w Europie",
        readMore: "https://300gospodarka.pl/300klimat/ekspertyza-polskie-zasoby-wodne-sa-niemal-najmniejsze-w-europie-w-przyszlosci-bedziemy-toczyli-o-nie-wojny"
    },
    {
        title: "Jedne z najbardziej zakwaszonych gleb w Europie",
        readMore: "https://wiadomosci.wp.pl/gus-bije-na-alarm-w-polsce-postepuje-zakwaszenie-ziemi-6584093087185632a"
    },
    {
        title: "22 miejsce w UE pod względem jakości życia",
        readMore: "https://wiadomosci.onet.pl/swiat/ranking-dobrobytu-narodow-polska-w-ogonie-ue/e2y0c",
    },
    {
        title: "Ogon Europy pod względem średniej powierzchni mieszkania",
        readMore: "https://www.domiporta.pl/poradnik/7,126870,23528390,polacy-w-ogonie-europejskiego-rankingu-sredniej-powierzchni.html",
    },
    {
        title: "Poziom nauczania na uczelniach",
        readMore: "http://perspektywy.pl/portal/index.php?option=com_content&view=article&id=13:swiatowy-ranking-uniwersytetow&Itemid=119"
    },
    {
        title: "Jeden z najbardziej zestresowanych narodów na świecie",
        readMore: "https://www.gazzettaitalia.pl/polacy-jednym-z-najbardziej-zestresowanych-narodow-na-swiecie/"
    },
    {
        title: "Ogon Europy pod względem oszczędzania",
        readMore: "https://finanse.rankomat.pl/poradniki/europejczycy-oszczedzanie-czy-zycie-na-kredyt/"
    },
    {
        title: "24 miejsce w UE pod względem gospodarki cyfrowej i społeczeństwa cyfrowego",
        readMore: "https://technologia.dziennik.pl/internet/artykuly/574664,polska-na-24-miejscu-w-unijnym-rankingu-gospodarki-cyfrowej.html"
    },
    {
        title: "Jedna z najwyższych liczb samobójstw w Europie wśród mężczyzn i dzieci",
        readMore: "https://noizz.pl/not-just-a-mood/raport-who-polscy-mezczyzni-w-czolowce-samobojstw-w-europie/nrdf05r",
    },
    {
        title: "31 miejsce w rankingu jakości życia dzieci w OECD",
        readMore: "https://businessinsider.com.pl/wiadomosci/gdzie-w-jakich-panstwach-dzieciom-zyje-sie-najlepiej-polska-na-koncu-rankingu/v96k5xc"
    },
    {
        title: "Ogon rankingu pod względem nierówności społecznych wśród dzieci",
        readMore: "https://www.rdc.pl/informacje/rosna-nierownosci-spoleczne-wsrod-dzieci-polska-jest-na-dole-miedzynarodowego-rankingu-posluchaj/",
    },
    {
        title: "Najbardziej homofobiczny kraj UE",
        readMore: "https://noizz.pl/lgbt/polska-najbardziej-homofobicznym-krajem-w-ue-ranking-i-mapa-ilga-2020-dotyczacy-lgbt/219919h",
    },
    {
        title: "Jeden z najniższych współczynników dzietności",
        readMore: "https://www.bankier.pl/wiadomosc/Polska-z-drugim-najnizszym-wspolczynnikiem-dzietnosci-w-UE-7503445.html"
    },
    {
        title: "Jeden z najwyższych wskaźników wyludniania się",
        readMore: "https://forsal.pl/artykuly/1414964,demograficzna-bomba-tyka-nasz-region-wyludni-sie-najszybciej-na-swiecie.html",
    },
    {
        title: "57 miejsce w rankingu demokracji",
        readMore: "https://oko.press/the-economist-w-rankingu-demokracji-polska-po-raz-pierwszy-nizej-niz-wegry/",
    },
    {
        title: "41 miejsce w rankingu zwalczania korupcji",
        readMore: "https://wgospodarce.pl/informacje/74482-polska-na-41-miejscu-w-rankingu-korupcji"
    },
    {
        title: "62 miejsce pod względem wolności prasy",
        readMore: "https://www.rp.pl/Media/200429817-Ranking-wolnosci-prasy-Polska-spada-5-rok-z-rzedu.html",
    },
]