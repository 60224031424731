import './App.css';
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PolicyIcon from '@material-ui/icons/Policy';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import CopyrightIcon from '@material-ui/icons/Copyright';

import { RankingList } from './components/RankingList';
import { LicenseDialog } from "./components/LicenseDialog";
import { ThanksDialog } from "./components/ThanksDialog";
import {PolicyDialog} from "./components/PolicyDialog";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { plPL } from '@material-ui/core/locale';

export const theme = createMuiTheme({
    spacing: 8,
    palette: {
        primary: {
            main: '#d32f2f',
        },
        secondary: {
            main: '#f44336',
        },
    },
}, plPL);

function App() {
    const [dialog, setDialog] = React.useState("none");

    const showDialog = (dialogType) => {
        setDialog(dialogType);
    };

    const closeDialog = () => {
        setDialog("none");
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (dialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialog]);

    return (
        <ThemeProvider theme={theme}>
            <Paper className="background-image">
                <Router>
                    <Container maxWidth="lg">
                        <header className="app-header">
                            Pisland Stronk
                        </header>
                        <Box mx={1} className="scrollable">
                            <Switch>
                                <Route exact path="/">
                                    <Home/>
                                </Route>
                                <Route path="*">
                                    <Redirect to="/"/>
                                </Route>
                            </Switch>
                        </Box>
                    </Container>
                </Router>
                <BottomNavigation
                    onChange={(event, newValue) => {
                        if (newValue === 0) {
                            showDialog("policy")
                        } else if (newValue === 1) {
                            showDialog("thanks")
                        } else if (newValue === 2) {
                            showDialog("license")
                        }
                    }}
                    showLabels
                >
                    <BottomNavigationAction label="Polityka prywatności" icon={<PolicyIcon/>}/>
                    <BottomNavigationAction label="Podziękowania" icon={<InfoOutlineIcon/>}/>
                    <BottomNavigationAction label="Licencje" icon={<CopyrightIcon/>}/>
                </BottomNavigation>
                <PolicyDialog open={dialog === "policy"} ref={descriptionElementRef} onClose={closeDialog}/>
                <ThanksDialog open={dialog === "thanks"} ref={descriptionElementRef} onClose={closeDialog}/>
                <LicenseDialog open={dialog === "license"} ref={descriptionElementRef}
                               onClose={closeDialog}/>
            </Paper>
        </ThemeProvider>
    );
}

function Home() {
    return <RankingList/>;
}

export default App;
