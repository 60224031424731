import React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {Typography} from "@material-ui/core";

export const ThanksDialog = React.forwardRef((props, ref) => (
    <Dialog
        open={props.open}
        onClose={props.onClose}
        scroll="paper"
        maxWidth="md"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
    >
        <DialogTitle disableTypography id="scroll-dialog-title">
            <Typography variant="h3">
                Podziękowania
            </Typography>
        </DialogTitle>
        <DialogContent dividers>
            <DialogContentText component="div"
                id="scroll-dialog-description"
                ref={ref}
                tabIndex={-1}
            >
                <Typography variant="h5" gutterBottom>
                    Podziękowania dla Fantom_s, który znalazł i zebrał informacje i artykuły
                </Typography>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} color="primary">
                Zamknij
            </Button>
        </DialogActions>
    </Dialog>
));